import $ from 'jquery';

export default {

    init: function () {
        $('.delete-files-button').click(function () {
            return window.confirm('Etes vous sur de vouloir supprimer ce fichier ?')
        });
        $('.delete-all-files-button').click(function () {
            return window.confirm('Etes vous sur de vouloir supprimer tous les fichiers ?')
        });
    },
}