import $ from 'jquery';

export default {

    init: function () {
        let t = this;
        t.initProductSelection();
    },


    initProductSelection: function() {
        $('#export-all-checkbox').change(function() {
            if ($(this).is(':checked')) {
                $('.export-form input[type="checkbox"]').prop('checked', true);
            }
            else {
                $('.export-form input[type="checkbox"]').prop('checked', false);
            }
        });

    },


}