import $ from 'jquery';

export default {

    init: function () {
        let t = this;
        t.initSaisieTitle();
        t.initSaisiePrice();
        t.initSortableRelationsAdd();
        t.initSaisieEbayFormat();
    },


    initSaisieTitle: function () {
        $('#sae0ea9285a_translations_fr_name').change(function () {
            if ($('#sae0ea9285a_translations_fr_description').val().trim() == '') {
                $('#sae0ea9285a_translations_fr_description').val($(this).val());
            }
            if ($('#sae0ea9285a_translations_fr_ebayName').val().trim() == '') {
                $('#sae0ea9285a_translations_fr_ebayName').val($(this).val().substring(0, 79));
            }
        });
        $('#sae0ea9285a_translations_en_name').change(function () {
            if ($('#sae0ea9285a_translations_en_description').val().trim() == '') {
                $('#sae0ea9285a_translations_en_description').val($(this).val());
            }
            if ($('#sae0ea9285a_translations_en_ebayName').val().trim() == '') {
                $('#sae0ea9285a_translations_en_ebayName').val($(this).val().substring(0, 79));
            }
        });
        $('#sae0ea9285a_translations_de_name').change(function () {
            if ($('#sae0ea9285a_translations_de_description').val().trim() == '') {
                $('#sae0ea9285a_translations_de_description').val($(this).val());
            }
            if ($('#sae0ea9285a_translations_de_ebayName').val().trim() == '') {
                $('#sae0ea9285a_translations_de_ebayName').val($(this).val().substring(0, 79));
            }
        });
        $('#sae0ea9285a_translations_es_name').change(function () {
            if ($('#sae0ea9285a_translations_es_description').val().trim() == '') {
                $('#sae0ea9285a_translations_es_description').val($(this).val());
            }
            if ($('#sae0ea9285a_translations_en_ebayName').val().trim() == '') {
                $('#sae0ea9285a_translations_en_ebayName').val($(this).val().substring(0, 79));
            }
        });
        $('#sae0ea9285a_translations_it_name').change(function () {
            if ($('#sae0ea9285a_translations_it_description').val().trim() == '') {
                $('#sae0ea9285a_translations_it_description').val($(this).val());
            }
            if ($('#sae0ea9285a_translations_it_ebayName').val().trim() == '') {
                $('#sae0ea9285a_translations_it_ebayName').val($(this).val().substring(0, 79));
            }
        });
    },


    initSaisiePrice: function () {
        $('#sae0ea9285a_priceFr').change(function () {
            if ($('#sae0ea9285a_priceDe').val().trim() == '') {
                $('#sae0ea9285a_priceDe').val($('#sae0ea9285a_priceFr').val());
            }
            if ($('#sae0ea9285a_priceEs').val().trim() == '') {
                $('#sae0ea9285a_priceEs').val($('#sae0ea9285a_priceFr').val());
            }
            if ($('#sae0ea9285a_priceIt').val().trim() == '') {
                $('#sae0ea9285a_priceIt').val($('#sae0ea9285a_priceFr').val());
            }
            if ($('#sae0ea9285a_priceNl').val().trim() == '') {
                $('#sae0ea9285a_priceNl').val($('#sae0ea9285a_priceFr').val());
            }
            if ($('#sae0ea9285a_priceUk').val().trim() == '') {
                $('#sae0ea9285a_priceUk').val($('#sae0ea9285a_priceFr').val());
            }
            if ($('#sae0ea9285a_priceUs').val().trim() == '') {
                $('#sae0ea9285a_priceUs').val($('#sae0ea9285a_priceFr').val());
            }
            if ($('#sae0ea9285a_ebayFrShop1Price').val().trim() == '') {
                $('#sae0ea9285a_ebayFrShop1Price').val($('#sae0ea9285a_priceFr').val());
            }
            if ($('#sae0ea9285a_ebayFrShop2Price').val().trim() == '') {
                $('#sae0ea9285a_ebayFrShop2Price').val($('#sae0ea9285a_priceFr').val());
            }
        })
    },


    initSortableRelationsAdd: function () {
        var $ = jQuery;
        $(document).on('sonata-admin-append-form-element', function (e) {
            if ($(e.target).attr('id').indexOf('productFeatures') != -1 || $(e.target).attr('id').indexOf('productKeywords') != -1) {
                var select2script = $(e.target).find('script').first().text();
                select2script = select2script.split("'),")[1];
                select2script = select2script.split(');')[0];
                var select2Options = JSON.parse(select2script)
                var lastOptionValue = select2Options[select2Options.length - 1].value;
                var values = $(e.target).find('.select2-container').data('select2').val();
                values.push(lastOptionValue);
                $(e.target).find('.select2-container').data('select2').val(values);
            }
        });
    },


    initSaisieEbayFormat: function () {
        let t = this;
        t.checkEbayFormat();
        $('#sae0ea9285a_ebayFrShop1Format, #sae0ea9285a_ebayFrShop2Format').change(function () {
            t.checkEbayFormat();
        });
    },


    checkEbayFormat: function () {
        $('#sonata-ba-field-container-sae0ea9285a_ebayFrShop1Duration').toggle($('#sae0ea9285a_ebayFrShop1Format').val() != 'StoresFixedPrice');
        $('#sonata-ba-field-container-sae0ea9285a_ebayFrShop2Duration').toggle($('#sae0ea9285a_ebayFrShop2Format').val() != 'StoresFixedPrice');
    },
}