import $ from 'jquery';

export default {

    init: function () {
        let t = this;
        t.initSaisieWorldwide();
    },


    initSaisieWorldwide: function () {
        let t = this;
        t.checkEbayWorldwide();
        $('#s680832e894_worldwide').change(function () {
            t.checkEbayWorldwide();
        });
    },


    checkEbayWorldwide:function() {
        $('#sonata-ba-field-container-s680832e894_locations').toggle($('#s680832e894_worldwide').val() == '0');
    },
}