/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */
// any CSS you require will output into a single css file (app.css in this case)
require('../scss/app.scss');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
const $ = require('jquery');



import AdminCommon from './admin/common';
import AdminTranslate from './admin/translate';
import AdminExport from './admin/export';
import AdminProduct from './admin/product';
import AdminEbayInternationalShipping from './admin/ebayInternationalShipping';

$(document).ready(function () {
    AdminCommon.init();
    AdminTranslate.init();
    AdminExport.init();
    AdminProduct.init();
    AdminEbayInternationalShipping.init();
});
