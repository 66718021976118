import $ from 'jquery';

export default {

    init: function () {
        let t = this;
        t.insertButtons();
        t.initButtonsEvents();
    },


    insertButtons: function() {
        $('.a2lix_translations').each(function() {
            let bloc = this;
            $(this).find('.a2lix_translationsLocales li').each(function(index) {
                if (index != 0) {
                    let locale = $(this).find('a').text();
                    let tab = $(bloc).find('.a2lix_translationsFields .tab-pane')[index];
                    let translateButton = '<button type="button" class="translate-button" data-locale="' + locale.toLowerCase().trim() + '">Traduire</button>';
                    $(translateButton).prependTo(tab);
                }
            });
        });
    },


    initButtonsEvents: function() {
        $('.translate-button').click(function() {
            let locale = $(this).data('locale');
            let button = this;
            $(this).parent().find('input, textarea').each(function() {
                let input = this;
                let baseText = $('#' + $(this).attr('id').replace('_' + locale + '_', '_fr_')).val();
                $(input).prop('disabled', true);
                $(button).prop('disabled', true);
                $.ajax({
                    url:'/ajax/translate',
                    type: 'POST',
                    dataType: 'text',
                    data: {
                        'texte': baseText,
                        'locale': locale,
                    },
                    async: true,
                    success: function (text)
                    {
                        $(input).val(text);
                        $(input).prop('disabled', false);
                        $(button).prop('disabled', false);
                    }
                });
            });
        });
    }

}